@import 'colors';
@import 'breakpoints';

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  font-size: 62.5%; // 1rem = 10px

  @include media-lg {
    font-size: 56.25%;
  }
}

body {
  font-family: 'PT Sans', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.8rem; // font size for text elements
  line-height: 1.5;
  color: map-get($colors, 'font-dark');

  box-sizing: border-box;
}

html,
body {
  height: 100%;
}
