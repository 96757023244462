@import 'colors';

.btn,
.ButtonFilled {
  display: inline-block;

  padding: 1rem 2.2rem;

  border: 2px solid transparent;
  border-radius: 100px;

  font-family: 'PT Sans', 'sans-serif';
  font-weight: bold;
  font-style: normal;
  font-size: 1.8rem;
  line-height: 1;
  text-decoration: none;

  cursor: pointer;

  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  outline: none;

  &:disabled {
    opacity: 0.8;

    cursor: not-allowed;
  }

  &--scarlet {
    background: map-get($colors, 'scarlet');
    border-color: map-get($colors, 'scarlet');

    color: map-get($colors, 'white');

    &:not(:disabled) {
      &:focus,
      &:hover {
        background: map-get($colors, 'scarlet:hover');
        border-color: map-get($colors, 'scarlet:hover');
      }

      &:active {
        background: map-get($colors, 'scarlet:active');
        border-color: map-get($colors, 'scarlet:active');
      }
    }

    &-outline {
      background: map-get($colors, 'white');
      border-color: map-get($colors, 'scarlet');

      color: map-get($colors, 'scarlet');

      &:not(:disabled) {
        &:focus,
        &:hover {
          background: map-get($colors, 'scarlet:hover');
          border-color: map-get($colors, 'scarlet:hover');

          color: map-get($colors, 'white');
        }

        &:active {
          background: map-get($colors, 'scarlet:active');
          border-color: map-get($colors, 'scarlet:active');

          color: map-get($colors, 'white');
        }
      }
    }
  }

  &--teal {
    background: map-get($colors, 'teal');
    border-color: map-get($colors, 'teal');

    color: map-get($colors, 'white');

    &:not(:disabled) {
      &:focus,
      &:hover {
        background: map-get($colors, 'teal:hover');
        border-color: map-get($colors, 'teal:hover');
      }

      &:active {
        background: map-get($colors, 'teal:active');
        border-color: map-get($colors, 'teal:active');
      }
    }
  }

  &--white {
    background: map-get($colors, 'white');
    border-color: map-get($colors, 'white');

    color: map-get($colors, 'font-dark');

    &:not(:disabled) {
      &:focus,
      &:hover {
        background: map-get($colors, 'white:hover');
        border-color: map-get($colors, 'white:hover');
      }

      &:active {
        background: map-get($colors, 'white:active');
        border-color: map-get($colors, 'white:active');
      }
    }
  }
}

.SimpleButton {
  display: inline-block;

  margin: 0;
  padding: 0;

  background: transparent;
  border: none;

  font: inherit;
  color: inherit;
  text-decoration: none;

  cursor: pointer;
  transition: opacity 0.3s ease;

  &:disabled {
    opacity: 0.75;

    cursor: not-allowed;
  }

  &:hover {
    opacity: 0.75;
  }
}

.button_new-item {
  display: inline-block;

  margin: 1em 0;
  padding: 1.2rem 2.4rem;

  border: none;
  border-radius: 100px;

  font-family: 'PT Sans', 'sans-serif';
  font-weight: bold;
  font-style: normal;
  font-size: 1.8rem;
  line-height: 1;
  text-decoration: none;
  color: map-get($colors, 'white');

  background: map-get($colors, 'scarlet');

  cursor: pointer;
  transition: background-color 0.3s ease;
  outline: none;

  &:disabled {
    opacity: 0.8;

    cursor: not-allowed;
  }

  &:not(:disabled) {
    &:focus,
    &:hover {
      background: map-get($colors, 'scarlet:hover');
    }

    &:active {
      background: map-get($colors, 'scarlet:active');
    }
  }
}
