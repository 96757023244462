@import 'colors';

.link {
  background: transparent;
  border: none;

  font: inherit;
  color: inherit;
  text-decoration: none;

  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.75;
  }
}

.uppercased-link {
  position: relative;

  font: inherit;
  font-weight: bold;
  color: inherit;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1.2px;

  transition: opacity 0.3s ease;

  &:hover,
  &:focus {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.7;
  }

  &::after {
    content: '';

    height: 1px;

    position: absolute;
    right: 0;
    bottom: 3px;
    left: 0;

    background: currentColor;
  }
}

.underlined-link {
  font-weight: bold;
  text-decoration: underline;
  color: map-get($colors, 'teal');

  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover,
  &:focus {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.7;
  }
}

.text-bold {
  font-weight: bold;
}

.page {
  &__container {
    max-width: 120rem;

    margin: 0 auto;
    padding: 0 2.4rem;
  }

  &__title {
    font-size: 3.6rem;

    margin: 1.15em 0 0.65em;
  }

  &__paragraph {
    margin: 0.75em 0;
  }
}

.paragraph {
  margin: 0.75em 0;
}
